footer{
  font-size: 12px;
  background-color: #f0f0f0;
  color: #5c5c5c;
  height: 80px;
  //position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  a{
    color: #1b71ba;
  }
}
