.ssu-table-elements{
  &__plus{
    &__td-btn {
        display: inline-block;
        padding: 9px 14px;
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.42857143;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        -ms-touch-action: manipulation;
        touch-action: manipulation;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-image: none;
        border: 1px solid transparent;

        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
    }
  }
  &__minus{
    &__td-btn{
      display: inline-block;
      padding: 9px 14px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      border: 1px solid transparent;

      color: #fff;
      background-color: #d9534f;
      border-color: #d43f3a;
    }
  }
}

.ssu-table-elements tr {
  border-top: 5px solid transparent;
}