.ssu-forms-type {
  background-color: #2e8ad9;
  border-bottom: 1px solid #9bbdda;
  &__btn {

    &_single:hover,
    &_multi:hover {
      background-color: #45a9ff;
      border-color: transparent;
    }

    &_active {
      background-color: #45a9ff;
      border-color: transparent;
    }

    &_single {
      padding: 13px 25px;
      transition: all 0.1s;

      &_img {
        height: 19px;
        width: 19px;
        background: url('../img/btn/one_column.png');
      }
      &_text {
        margin-left: 30px;
        font-size: 15px;
        color: #fff;
      }
    }

    &_multi {
      padding: 13px 25px;
      margin-left: 10px;
      transition: all 0.1s;

      &_img {
        height: 19px;
        width: 19px;
        background: url('../img/btn/two_column.png');
      }
      &_text {
        margin-left: 35px;
        font-size: 15px;
        color: #fff;
      }
    }
  }

}