input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important; /* Цвет фона */
  -webkit-text-fill-color: #787878 !important; /* цвет текста */
  color: #787878 !important; /* цвет текста */
}

.ssu-login {
  margin: 68px auto 0px;
  padding: 20px 20px;
  background-color: #fff;
  min-height: 275px;
  width: 430px;
  border-bottom: 1px solid #9ec7e5;

  &__form {
    position: relative;
  }

  &__label {
    padding: 13px 0px 33px;
    font-size: 18px;
  }

  &__input-email,
  &__input-password {
    height: 15px;
    width: 398px;
    padding: 15px;
    border: 1px solid #cecece;
    color: #787878;
  }

  &__input-password {
    border-top: none;
    margin-bottom: 22px;
  }

  &__input-img-email,
  &__input-img-password {
    position: absolute;
    height: 46px;
    width: 46px;
    background: url('../img/sprites/sprite_login.png');
    top: 68px;
    right: 0;
  }

  &__input-img-password {
    background-position: 0 -46px;
    top: 112px;
  }

  &__remember {
    padding-left: 10px;

    &_label {
      font-size: 13px;
      font-weight: normal;
      padding-left: 10px;
      color: #787878;
    }
    &_checkbox {
      padding-left: 10px;
    }
  }

  &__help-block {
    margin-bottom: 10px;
  }

  &__btn {
    padding-top: 33px;

    &_enter {
      padding: 11px 25px;
      font-size: 15px;
      border: none;
      color: #fff;

      &_img {
        width: 34px;
        height: 17px;
        display: block;
        background: url('../img/btn/arrow.png') no-repeat;
        margin-left: 25px;
      }
    }
  }

}

.checkbox-sm {
  &_label {
    cursor: pointer;
    padding-left: 10px;
    vertical-align: text-top;
    color: #333;
  }
  &_img {
    height: 15px;
    width: 15px;
    display: block;
    position: relative;
  }
  &_input + span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: url('../img/sprites/sprite_checkbox_top_bg_no_small.png') 0 0px no-repeat;
    transition: background-position 0.3s ease;
    border-top: 1px solid #c9c9c9;
    cursor: pointer;
  }
  &_input:checked + span {
    background-position: 0 -15px;
  }
}