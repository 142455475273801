.alert {
  margin-top: 1px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;

  &-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
  }
  &-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }
  &-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
  }
}
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}
button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}