button{
  background-color: transparent;
}

.ssu-btn{
  border-radius: 26px;
  cursor: pointer;

  &_square {
    border-radius: 0px;
  }
  &_circle {
    border-radius: 26px;
  }


  &_br-primary {
    border: 2px solid #1b71ba;
    color: #1b71ba;
  }
  &_br-light{
    border: 2px solid #fff;
    color: #fff;
  }


  &_bg-primary{
    background-color: #1b71ba; //#004d90;
  }
  &_bg-green{
    background-color: #21a961;
  }
  &_bg-pagination{
    background-color: #004d90;
  }

}

.radio,
.checkbox {
  &_label {
    cursor: pointer;
    vertical-align: sub;
    color: #333;
  }
  &_img {
    height: 22px;
    width: 22px;
    margin-right: 10px;
    display: block;
    position: relative;
  }
  &_input,
  &_input_disabled{
    visibility: hidden;
  }
  &_input + span,
  &_input_disabled + span{
    position: absolute;
    left: 0;
    top: 0;
    height: 22px;
    width: 22px;
    background: url('../img/sprites/sprite_checkbox_top_bg_no.png') 0 0 no-repeat;
    transition: background-position 0.3s ease;
    border-top: 1px solid #c9c9c9;
    cursor: pointer;
  }
  &_input_disabled + span {
    background: url('../img/sprites/sprite_checkbox_disabled_top_bg_no.png') 0 0 no-repeat;
  }
  &_input:checked + span,
  &_input_disabled:checked + span{
    background-position: 0 -22px;
  }
}

.radio {
  &_input + span,
  &_input_disabled + span{
    background: none;
    border: 1px solid #c9c9c9;
    border-radius: 50%;
  }
  &_input:checked + span,
  &_input_disabled:checked + span{
    border: 4px solid #1b71ba;
    height: 17px;
    width: 17px;
  }
  &_input_disabled:checked + span {
    border: 4px solid #bebebe;
  }
}