.container{
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px){
  .container{
    width: 750px;
  }
}
@media (min-width: 992px){
  .container{
    width: 970px;
  }
}
@media (min-width: 1200px){
  .container{
    width: 1170px;
  }
}
.container-fluid{
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  min-height: 100%;
}