.ssu-constructor-form {
  &__name-new-form {
    padding-top: 35px;

    &_label {
      margin-left: 5px;
      margin-bottom: 20px;
      font-size: 24px;
      color: #333;
    }
    &_input {
      height: 60px;
      background-color: #fff;
      border: 1px solid #eee;
      padding: 0 25px;
      font-size: 18px;
      box-sizing: border-box;
    }
  }

  &__btn{
    &_save-new-form {
      position: relative;
      top: 21px;
      color: #fff;
      font-size: 15px;
      background-color: #21a961;
      padding: 10px 20px 10px 30px;
      border: none;
    }
    &_accept_forms_img {
      height: 19px;
      width: 19px;
      background: url('../img/btn/accept.png') no-repeat;
      margin-left: 40px;
    }
  }
}

.ssu-filters {
  &__td {
    width: 220px;
    padding-right: 20px;

    &_label {
      position: relative;
      padding: 25px 0 10px 5px;
      font-size: 15px;
      color: #333;
    }
    &_input {
      height: 40px;
      width: 220px;
      padding: 0 15px;
      background-color: #fff;
      border: 1px solid #eee;
      font-size: 15px;
      box-sizing: border-box;
      cursor: pointer;

      &_list {
        width: 100%;
        box-sizing: border-box;
        padding: 10px 10px 10px 0;
        //max-width: 399px;

        overflow: hidden;
        white-space: nowrap;
        &_img-plus {
          content: "";
          height: 38px;
          width: 40px;
          background: url('../img/btn/add_in_list_gradient.png') -60px 0px no-repeat;
          display: block;
          position: absolute;
          top: 53px;
          right: 0;
          cursor: pointer;
          z-index: 10;
        }
        &_img-gradient {
          content: "";
          height: 38px;
          width: 60px;
          background: url('../img/btn/add_in_list_gradient.png') 0px 0px no-repeat;
          display: block;
          position: absolute;
          top: 52px;
          right: 38px;
          cursor: pointer;
          z-index: 10;
        }
        &_img-gradient-right {
          content: "";
          height: 38px;
          width: 60px;
          background: url('../img/btn/add_in_list_gradient.png') 0px 0px no-repeat;
          display: block;
          position: absolute;
          top: 52px;
          right: 1px;
          cursor: pointer;
          z-index: 10;
        }
      }
    }

    &_input:disabled {
      background: #d4d4d4;
      cursor: default;
    }
    &_w-390 {
      width: 390px;
      max-width: 390px;
    }
  }
}

.ssu-list-fields-will-form {
  width: 49%;
  margin-top: 30px;
  margin-right: 2%;
  cursor: default;

  &__empty{
    height: 60px;
    background: #fff;
    font-size: 18px;
    color: #333;
    border: 1px solid #eaeaea;
  }
  &__name-table {
    height: 90px;
    font-size: 18px;
    color: #333;
  }
  &__label {
    height: 32px;
    color: #636363;
    vertical-align: top;

    &__name-field {
      color: #333;
    }
    &__required {
      width: 95px;
    }
  }

  &__field {
    background: #fff;
    height: 60px;
    border: 1px solid #eaeaea;

    &__td {
      &__move {
        height: 59px;
        width: 47px;
        background: url('../img/move.png');
      }
      &__type-field {
        min-width: 160px;
        color: #636363;
      }
      &__required {
        width: 95px;
      }
      &__remove {
        width: 60px;
        cursor: pointer;
      }
    }

    &__remove {
      height: 29px;
      width: 28px;
      background: url('../img/sprites/sprite_circle_btn.png') no-repeat;
      margin-right: 15px;
    }
  }

}

.ssu-list-existing-fields {
  width: 49%;
  margin-top: 30px;
  cursor: default;

  &__name-table {
    height: 90px;
    font-size: 18px;
    color: #333;
  }
  &__label {
    height: 32px;
    color: #636363;
    vertical-align: top;

    &__name-field {
      color: #333;
    }
    &__actions-with-fields {
      padding-right: 20px;
    }
  }

  &__field {
    background: #fff;
    height: 60px;
    border: 1px solid #eaeaea;

    &__td {
      &__add {
        width: 60px;
        cursor: pointer;
        transition: margin-left 0.3s ease;
      }
      &__add:hover .ssu-list-existing-fields__field__add {
        margin-left: 10px;
      }
      &__type-field {
        color: #636363;
      }
      &__review {
        padding-right: 15px;
        color: #636363;
      }
    }

    &__add {
      height: 29px;
      width: 28px;
      background: url('../img/sprites/sprite_circle_btn.png') 0 -29px no-repeat;
      margin-left: 15px;
      transition: margin-left 0.3s ease;
    }

  }

}

.ui-state-highlight {
  height: 60px;
  background-color: #21a961;
}
.ui-handle:hover {
  cursor: n-resize;
}

.ssu-btn {
  &__managers_form ,
  &__providers_of_information ,
  &__departments_connect_to_form {
    position: relative;
    background: #dfdfdf;
    padding: 2px 15px;
    margin-left: 10px;
  }
}