.ssu-pagination{
  font-size: 12px;
  margin-top: 40px;

  &__btn{
    padding: 11px 20px;
    font-size: 12px;
    margin: 0 50px;
    color: #fff;

    &_disabled{
      background-color: rgba(165, 165, 165, 0.5);//rgba(0, 77, 144, 0.5);//#d6d6d6;
      cursor: default;
    }

    &_img-left{
      width: 34px;
      height: 17px;
      display: block;
      background: url('../img/btn/arrow.png') no-repeat;
      transform: scaleX(-1);
      margin-right: 10px;
    }
    &_img-right{
      width: 34px;
      height: 17px;
      display: block;
      background: url('../img/btn/arrow.png') no-repeat;
      margin-left: 10px;
    }

    &_number-page{
      padding: 11px 15px;
      font-size: 12px;
      margin: 0 5px;
      border: 2px solid #004d90;
      background-color: transparent;
      color: #004d90;
      transition: all 0.3s ease;

      &_active{
        padding: 11px 15px;
        font-size: 12px;
        margin: 0 5px;
        border: 2px solid #004d90;
        background-color: #004d90;
        color: #fff;
      }
    }

    &_number-page:hover{
      background-color: #004d90;
      color: #fff;
    }
  }

}