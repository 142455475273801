.ssu-check-forms {

  &__info {
    background: #fff;
    margin-top: 25px;
    border: 1px solid #e4e4e4;
    cursor: default;

    &__header {
      height: 60px;
      border-bottom: 1px solid #e4e4e4;

      &_name-form {
        padding: 0 25px;
        font-size: 24px;
        color: #333;
      }
      &_btn {
        padding: 0 15px;
        &_remind-via-email {
          padding: 8px 15px;
          background-color: #45a9ff;
          color: #fff;
          border: none;
        }
      }
    }
    &__body {
      height: 80px;
      font-size: 15px;

      &__cell {
        padding: 0 25px;

        &_caption {
          color: #9d9d9d;
        }
        &_text {
          color: #333;
        }
      }
    }
  }

  &__content {
    width: 100%;
    border-bottom: 1px solid #e4e4e4;

    &__header {
      &_text {
        height: 18px;
        padding: 25px 5px 15px;
        font-size: 18px;
        color: #333;
        cursor: default;
      }
    }

    &__body {

      &_caption {
        color: #9d9d9d;
        font-size: 15px;
        margin: 25px 0px 10px 4px;
      }
      &_text {
        color: #333;
        font-size: 15px;
      }
      &_values {
        color: #333;
        font-size: 16px;
      }
      &_textarea {
        resize: none;
        width: 96%;
        border: 1px solid #e0e0e0;
        color: #333;
        font-size: 15px;
        padding: 10px;
      }
      &_sub-element {
        padding: 5px 0;
      }

      &_current pre,
      &_previous pre {
        font-family: Arial;
      }
      &_current {
        width: 50%;
        background: #fff;
        border: 1px solid #e4e4e4;
        padding: 0 25px 25px;
      }
      &_previous {
        width: 50%;
        background: #f0f0f0;
        border: 1px solid #e4e4e4;
        cursor: default;
        position: relative;
        padding: 0 25px 25px;

        &_img {
          &_next {
            height: 42px;
            width: 42px;
            background: url('../img/btn/versions_form.png') no-repeat;
            position: absolute;
            top: 25px;
            left: -21px;
            cursor: pointer;
          }
          &_previous {
            height: 42px;
            width: 42px;
            background: url('../img/btn/versions_form.png') no-repeat;
            transform: scale(-1,1);
            position: absolute;
            top: 25px;
            right: -21px;
            cursor: pointer;
          }
        }
      }
    }

  }

  &__btn {
    margin-top: 25px;
    height: 40px;
    width: 50%;
    color: #fff;
    font-size: 15px;

    &_reject-form,
    &_accept-form {
      padding: 10px 22px 10px 35px;
      color: #fff;
      font-size: 15px;
      border: none;
      background: #21a961;

      &_img {
        height: 19px;
        width: 19px;
        background: url('../img/sprites/sprite_check_forms.png') 0 0 no-repeat;
        margin-left: 30px;
      }
    }
    &_reject-form {
      background: #da4c7a;

      &_img {
        background: url('../img/sprites/sprite_check_forms.png') 0 -19px no-repeat;
      }
    }
  }

  &__message {
    width: 50%;
    padding-top: 30px;

    &_caption {
      padding-left: 5px;
      font-size: 18px;
      cursor: default;
      color: #333;
    }
    &_textarea {
      margin-top: 10px;
      padding: 20px;
      height: 75px;
      width: 94%;
      resize: none;
      font-size: 15px;
      font-family: Arial;
      background-color: #fff;
      border: 1px solid #e4e4e4;
    }
  }

  &__message-box {
    width: 100%;
    margin-top: 30px;
    background-color: #fff;
    border: 1px solid #e4e4e4;
  }

}

.message{
  &_info {
    padding: 10px;
    height: 18px;

    &_time-send,
    &_name-sender {
      font-size: 11px;
    }
    &_new-message {
      position: relative;
      padding: 0px 3px 1px;
      width: 22px;
      font-size: 11px;
      font-weight: 600;
      color: #fff;
      background-color: orange;
    }
  }
  &_content {
    padding: 0px 10px;
    font-size: 15px;
    text-align: justify;
  }
}