*{
    margin: 0;
    padding: 0px;
}

body{
    font-family: Arial;
    background-color: #f7f7f7;//#f0f0f0;
}

html,
body {
    height: 100%;
}

a{
    color: #fff;
}

table{
    border-collapse: collapse;
    border-spacing: 0px;
}

.select-style,
.input-style[type="text"],
.input-style[type="email"],
.input-style[type="number"],
.input-style[type="password"]{
    height: 40px;
    padding: 0 15px;
    background-color: #fff;
    border: 1px solid #dedede;
    font-size: 15px;
    box-sizing: border-box;
    color: #333;
}
.textarea-style{
    background-color: #fff;
    border: 1px solid #eee;
    font-size: 15px;
    color: #333;
    resize: none;
    height: 100px;
    width: 94%;
    padding: 15px;
    font-family: Arial;
}

.select-style:disabled,
.textarea-style:disabled,
.input-style[type="text"]:disabled,
.input-style[type="email"]:disabled,
.input-style[type="number"]:disabled,
.input-style[type="password"]:disabled{
    background: #d4d4d4;
}

:focus {
    outline: none;
}

input::-webkit-input-placeholder       {opacity: 1; transition: opacity 0.3s ease; color: #c5c5c5;}
input::-moz-placeholder                {opacity: 1; transition: opacity 0.3s ease; color: #c5c5c5;}
input:-moz-placeholder                 {opacity: 1; transition: opacity 0.3s ease; color: #c5c5c5;}
input:-ms-input-placeholder            {opacity: 1; transition: opacity 0.3s ease; color: #c5c5c5;}

input:focus::-webkit-input-placeholder {opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder          {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder           {opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder      {opacity: 0; transition: opacity 0.3s ease;}

.bg-img{
    background-image: url('../img/bg.jpg');
    background-size: cover;
}
.bg-transparent{
    background-color: transparent;
}


.wrapper {
    position: relative;
    min-height: 100%;
}
.content {
    padding-bottom: 80px;
}
.max-width{
    max-width: 100%;//1400px;
    margin: 0 auto;
}


.color-white{
    color: #fff;
}
.color-black{
    color: #000;
}


.pull-right{
    float: right;
}
.pull-left{
    float: left;
}


.display-t{
    display: table;
}
.display-t-c{
    display: table-cell;
    vertical-align: middle;
}
.display-i{
    display: inline;
}
.display-i-b{
    display: inline-block;
}


.label-slim{
    font-weight: normal;
}


.outline-n{
    outline: none;
}
.text-decoration-n{
    text-decoration: none;
}
.text-center{
    text-align: center;
}
.text-center-v{
    vertical-align: middle;
}


.caret{
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 4px dashed;
    border-top: 4px solid;
    border-right: 4px solid transparent;
    border-left: 4px solid transparent;
}


.w-100{
    width: 100%;
}
.w-50{
    width: 50%;
}
.h-100{
    height: 100px;
}
.h-80{
    height: 80px;
}
.h-73{
    height: 73px;
}
.h-40{
    height: 40px;
}

.cursor-default{
    cursor: default;
}

.row{
    margin-left: -20px;
    margin-right: -20px;
}
.row-left{
    margin-left: -20px;
}
.row-right{
    margin-right: -20px;
}

.help-block {
    color: red;
    padding: 0px 10px;
}

.img-responsive {
    display: block;
    max-width: 100%;
    height: auto;
}

.red {
    color: red;
}


.return{
    width: 20px;
    height: 20px;
    background: url('../img/btn/reset.png');
    transition: all 0.3s ease;
}
.return:hover {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
}