@charset "UTF-8";
* {
  margin: 0;
  padding: 0px;
}

body {
  font-family: Arial;
  background-color: #f7f7f7;
}

html,
body {
  height: 100%;
}

a {
  color: #fff;
}

table {
  border-collapse: collapse;
  border-spacing: 0px;
}

.select-style,
.input-style[type="text"],
.input-style[type="email"],
.input-style[type="number"],
.input-style[type="password"] {
  height: 40px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #dedede;
  font-size: 15px;
  box-sizing: border-box;
  color: #333;
}

.textarea-style {
  background-color: #fff;
  border: 1px solid #eee;
  font-size: 15px;
  color: #333;
  resize: none;
  height: 100px;
  width: 94%;
  padding: 15px;
  font-family: Arial;
}

.select-style:disabled,
.textarea-style:disabled,
.input-style[type="text"]:disabled,
.input-style[type="email"]:disabled,
.input-style[type="number"]:disabled,
.input-style[type="password"]:disabled {
  background: #d4d4d4;
}

:focus {
  outline: none;
}

input::-webkit-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #c5c5c5;
}

input::-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #c5c5c5;
}

input:-moz-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #c5c5c5;
}

input:-ms-input-placeholder {
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #c5c5c5;
}

input:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

input:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.bg-img {
  background-image: url("../img/bg.jpg");
  background-size: cover;
}

.bg-transparent {
  background-color: transparent;
}

.wrapper {
  position: relative;
  min-height: 100%;
}

.content {
  padding-bottom: 80px;
}

.max-width {
  max-width: 100%;
  margin: 0 auto;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #000;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.display-t {
  display: table;
}

.display-t-c {
  display: table-cell;
  vertical-align: middle;
}

.display-i {
  display: inline;
}

.display-i-b {
  display: inline-block;
}

.label-slim {
  font-weight: normal;
}

.outline-n {
  outline: none;
}

.text-decoration-n {
  text-decoration: none;
}

.text-center {
  text-align: center;
}

.text-center-v {
  vertical-align: middle;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.w-100 {
  width: 100%;
}

.w-50 {
  width: 50%;
}

.h-100 {
  height: 100px;
}

.h-80 {
  height: 80px;
}

.h-73 {
  height: 73px;
}

.h-40 {
  height: 40px;
}

.cursor-default {
  cursor: default;
}

.row {
  margin-left: -20px;
  margin-right: -20px;
}

.row-left {
  margin-left: -20px;
}

.row-right {
  margin-right: -20px;
}

.help-block {
  color: red;
  padding: 0px 10px;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

.red {
  color: red;
}

.return {
  width: 20px;
  height: 20px;
  background: url("../img/btn/reset.png");
  transition: all 0.3s ease;
}

.return:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.container {
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  min-height: 100%;
}

button {
  background-color: transparent;
}

.ssu-btn {
  border-radius: 26px;
  cursor: pointer;
}

.ssu-btn_square {
  border-radius: 0px;
}

.ssu-btn_circle {
  border-radius: 26px;
}

.ssu-btn_br-primary {
  border: 2px solid #1b71ba;
  color: #1b71ba;
}

.ssu-btn_br-light {
  border: 2px solid #fff;
  color: #fff;
}

.ssu-btn_bg-primary {
  background-color: #1b71ba;
}

.ssu-btn_bg-green {
  background-color: #21a961;
}

.ssu-btn_bg-pagination {
  background-color: #004d90;
}

.radio_label,
.checkbox_label {
  cursor: pointer;
  vertical-align: sub;
  color: #333;
}

.radio_img,
.checkbox_img {
  height: 22px;
  width: 22px;
  margin-right: 10px;
  display: block;
  position: relative;
}

.radio_input, .radio_input_disabled,
.checkbox_input,
.checkbox_input_disabled {
  visibility: hidden;
}

.radio_input + span,
.radio_input_disabled + span,
.checkbox_input + span,
.checkbox_input_disabled + span {
  position: absolute;
  left: 0;
  top: 0;
  height: 22px;
  width: 22px;
  background: url("../img/sprites/sprite_checkbox_top_bg_no.png") 0 0 no-repeat;
  transition: background-position 0.3s ease;
  border-top: 1px solid #c9c9c9;
  cursor: pointer;
}

.radio_input_disabled + span,
.checkbox_input_disabled + span {
  background: url("../img/sprites/sprite_checkbox_disabled_top_bg_no.png") 0 0 no-repeat;
}

.radio_input:checked + span,
.radio_input_disabled:checked + span,
.checkbox_input:checked + span,
.checkbox_input_disabled:checked + span {
  background-position: 0 -22px;
}

.radio_input + span,
.radio_input_disabled + span {
  background: none;
  border: 1px solid #c9c9c9;
  border-radius: 50%;
}

.radio_input:checked + span,
.radio_input_disabled:checked + span {
  border: 4px solid #1b71ba;
  height: 17px;
  width: 17px;
}

.radio_input_disabled:checked + span {
  border: 4px solid #bebebe;
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: none;
  pointer-events: none;
  cursor: default;
}

.modal__heading {
  margin: 40px auto 0;
  position: relative;
  width: 652px;
  height: 60px;
  background-color: #1b71ba;
  color: #fff;
  font-size: 18px;
}

.modal__heading__text {
  padding: 22px 20px;
}

.modal__body {
  width: 650px;
  max-height: 500px;
  position: relative;
  margin: 0px auto;
  border: 1px solid #e9e9e9;
  background: #fff;
  overflow: auto;
}

.modal__close {
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 20px;
  right: 20px;
  background: url("../img/btn/reset.png");
  transition: transform 0.3s ease;
}

.modal__close:hover {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.modal__table {
  width: 100%;
}

.modal__table_td {
  padding: 10px;
}

.modal:target {
  display: block;
  pointer-events: auto;
}

.alert {
  margin-top: 1px;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: .2;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: .5;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.ssu-list-table {
  border-collapse: collapse;
  font-size: 15px;
}

.ssu-list-table__header {
  background: #e0e0e0;
  height: 60px;
}

.ssu-list-table__header__td:first-child {
  padding-left: 30px;
  width: 450px;
}

.ssu-list-table__header__td {
  cursor: default;
}

.ssu-list-table__header__td:last-child {
  padding-right: 30px;
  cursor: default;
}

.ssu-list-table__header__text {
  color: #333;
  cursor: pointer;
}

.ssu-list-table__header_arrows-sort_img {
  width: 19px;
  height: 19px;
  background: url("../img/btn/arrows_sort.png");
  vertical-align: sub;
  cursor: pointer;
}

.ssu-list-table__a-name {
  color: #1b71ba;
  transition: color 0.3s ease;
}

.ssu-list-table__a-name:hover {
  color: #333;
}

.ssu-list-table__body {
  background-color: #fff;
  height: 60px;
  cursor: default;
}

.ssu-list-table__body__td:first-child {
  padding-left: 30px;
}

.ssu-list-table__body__td {
  border-bottom: 2px solid #f0f0f0;
  color: #636363;
  padding: 0 10px;
}

.ssu-list-table__body:hover {
  background-color: #fafafa;
}

.ssu-list-table__btn_actions, .ssu-list-table__btn_actions-red, .ssu-list-table__btn_actions-green {
  padding: 8px 16px;
  font-size: 12px;
  color: #494949;
  transition: all 0.2s ease;
  margin: 0 5px;
}

.ssu-list-table__btn_actions:hover {
  background-color: #1b71ba;
  color: #fff;
}

.ssu-list-table__btn_actions-red:hover {
  background-color: #fb4b7c;
  color: #fff;
}

.ssu-list-table__btn_actions-green:hover {
  background-color: #21a961;
  color: #fff;
}

.glyphicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: 'Glyphicons Halflings';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.glyphicon-plus:before {
  content: "\002b";
}

.glyphicon-minus:before {
  content: "\2212";
}

.ssu-card {
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  border: 1px solid #dedede;
}

.ssu-card__header {
  height: 60px;
  background-color: #1b71ba;
}

.ssu-card__header_user-name {
  color: #fff;
  padding: 0 20px;
}

.ssu-card__body {
  padding: 20px;
}

.ssu-card__body_caption {
  color: #9d9d9d;
  font-size: 15px;
  padding: 20px 5px 5px;
}

.ssu-card__body_caption:first-child {
  padding-top: 0px;
}

.ssu-card__body_input {
  border: 1px solid #dedede;
}

.ssu-card__body_textarea {
  border: 1px solid #dedede;
  resize: none;
  font-size: 15px;
  padding: 15px;
  width: 94%;
}

.ssu-card__body__btn {
  padding-top: 20px;
}

.ssu-card__body__btn_save {
  padding: 10px 30px;
  border: 2px solid #21a961;
  background-color: #21a961;
  color: #fff;
}

.ssu-card__body__btn_generate-password {
  padding-top: 3px;
  border: none;
  color: blue;
}

.error-validate {
  font-size: 15px;
  color: red;
  margin-top: 5px;
}

.ssu-header {
  height: 73px;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
}

.ssu-header__name-system {
  padding-left: 25px;
  font-size: 18px;
  color: #5a5a5a;
}

.ssu-header__user-name {
  height: 72px;
  padding: 1px 25px;
  vertical-align: middle;
  color: #1b71ba;
}

.ssu-header__user-name_text {
  text-decoration: underline;
}

.ssu-header__user-name_caret {
  margin-left: 5px;
}

.ssu-header__user-name:hover {
  border-bottom: none;
  color: #494949;
}

.ssu-header__user-name:hover .ssu-header__user-name_text {
  text-decoration: none;
}

.ssu-header__user-name:hover .drop-down {
  top: 73px;
  right: 0px;
  opacity: 1;
  width: 271px;
  border-top: none;
  border: 1px solid #e0e0e0;
}

.ssu-header__btn_how-it-works {
  padding: 11px 20px;
  margin-right: 20px;
}

.drop-down {
  position: absolute;
  top: -215px;
  list-style: none;
  background: #fff;
  border: 1px solid #e0e0e0;
  opacity: 1;
  z-index: 1000;
}

.drop-down__li {
  padding: 9px 25px;
}

.drop-down__li_edit-profile_img {
  height: 19px;
  width: 19px;
  margin-right: 20px;
  background: url("../img/sprites/sprite_user_menu.png") 0 0px;
}

.drop-down__li_log-manager_img {
  height: 19px;
  width: 19px;
  margin-right: 20px;
  background: url("../img/sprites/sprite_user_menu.png") 0 38px;
}

.drop-down__li_exit_img {
  height: 19px;
  width: 19px;
  margin-right: 20px;
  background: url("../img/sprites/sprite_user_menu.png") 0 19px;
}

.drop-down__a {
  color: #494949;
  text-decoration: underline;
}

.drop-down__a:hover {
  text-decoration: none;
}

.drop-down__a:first-child .drop-down__li {
  padding-top: 25px;
  padding-bottom: 25px;
}

.drop-down__a:nth-child(2) .drop-down__li {
  padding-bottom: 25px;
}

.drop-down__a:last-child .drop-down__li {
  padding: 25px;
  border-top: 1px solid #e0e0e0;
}

.ssu-navbar-top {
  height: 73px;
  color: #fff;
  background-color: #1b71ba;
  font-size: 15px;
  overflow: hidden;
}

.ssu-navbar-top_wrapper {
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}

.ssu-navbar-top__li {
  display: inline;
  color: #fff;
}

.ssu-navbar-top__li_active {
  text-decoration-color: #fff;
  background-color: #2e8ad9;
}

.ssu-navbar-top__a {
  padding: 28px;
  text-decoration: none;
  transition: all 0.1s;
}

.ssu-navbar-top__a:hover {
  text-decoration-color: #fff;
  background-color: #2e8ad9;
}

.ssu-navbar-top__btn_how-it-works {
  padding: 10px 30px;
}

.ssu-forms-type {
  background-color: #2e8ad9;
  border-bottom: 1px solid #9bbdda;
}

.ssu-forms-type__btn_single:hover, .ssu-forms-type__btn_multi:hover {
  background-color: #45a9ff;
  border-color: transparent;
}

.ssu-forms-type__btn_active {
  background-color: #45a9ff;
  border-color: transparent;
}

.ssu-forms-type__btn_single {
  padding: 13px 25px;
  transition: all 0.1s;
}

.ssu-forms-type__btn_single_img {
  height: 19px;
  width: 19px;
  background: url("../img/btn/one_column.png");
}

.ssu-forms-type__btn_single_text {
  margin-left: 30px;
  font-size: 15px;
  color: #fff;
}

.ssu-forms-type__btn_multi {
  padding: 13px 25px;
  margin-left: 10px;
  transition: all 0.1s;
}

.ssu-forms-type__btn_multi_img {
  height: 19px;
  width: 19px;
  background: url("../img/btn/two_column.png");
}

.ssu-forms-type__btn_multi_text {
  margin-left: 35px;
  font-size: 15px;
  color: #fff;
}

.ssu-search_input {
  width: 350px;
  height: 43px;
  border: 1px solid #e0e0e0;
  background: #fff;
  outline: none;
  padding: 0 20px;
}

.ssu-search_filter {
  height: 19px;
  width: 19px;
  background: url("../img/btn/filter.png") no-repeat;
  margin-left: 30px;
  padding-left: 25px;
}

.ssu-search_advanced-search {
  text-decoration: underline;
  cursor: pointer;
  font-size: 15px;
}

.ssu-btn_search {
  height: 45px;
  width: 45px;
  border: none;
  border-radius: 0px;
  background-color: #1b71ba;
}

.ssu-btn_search_img {
  width: 19px;
  height: 19px;
  display: inline-block;
  background: url("../img/btn/magnifier.png") no-repeat;
}

.ssu-btn_reset {
  height: 45px;
  width: 45px;
  border: 2px solid #bebebe;
  background-color: none;
  margin-left: 10px;
}

.ssu-btn_reset_img {
  width: 19px;
  height: 19px;
  display: inline-block;
  background: url("../img/btn/reset.png") no-repeat;
}

.ssu-forms-status {
  padding: 6px 0;
}

.ssu-forms-status__btn_all-forms, .ssu-forms-status__btn_new-forms, .ssu-forms-status__btn_accept-forms, .ssu-forms-status__btn_reject-forms {
  border: none;
  padding: 0px 20px 0px 5px;
  margin-right: 5px;
  transition: all 0.1s;
}

.ssu-forms-status__btn_all-forms_img, .ssu-forms-status__btn_new-forms_img, .ssu-forms-status__btn_accept-forms_img, .ssu-forms-status__btn_reject-forms_img {
  padding-right: 9px;
  font-size: 15px;
  height: 29px;
  width: 29px;
}

.ssu-forms-status__btn_all-forms_text, .ssu-forms-status__btn_new-forms_text, .ssu-forms-status__btn_accept-forms_text, .ssu-forms-status__btn_reject-forms_text {
  text-decoration: underline;
  line-height: 29px;
}

.ssu-forms-status__btn_all-forms_img {
  background: url("../img/sprites/sprite_circle_btn.png") 0 -87px no-repeat;
}

.ssu-forms-status__btn_new-forms_img {
  background: url("../img/sprites/sprite_circle_btn.png") 0 -116px no-repeat;
}

.ssu-forms-status__btn_accept-forms_img {
  background: url("../img/sprites/sprite_circle_btn.png") 0 -58px no-repeat;
}

.ssu-forms-status__btn_reject-forms_img {
  background: url("../img/sprites/sprite_circle_btn.png") 0 0px no-repeat;
}

.ssu-forms-status__btn_all-forms_active, .ssu-forms-status__btn_all-forms:hover, .ssu-forms-status__btn_new-forms_active, .ssu-forms-status__btn_new-forms:hover {
  background-color: #1b71ba;
  color: #fff;
}

.ssu-forms-status__btn_accept-forms_active, .ssu-forms-status__btn_accept-forms:hover {
  background-color: #21a961;
  color: #fff;
}

.ssu-forms-status__btn_reject-forms_active, .ssu-forms-status__btn_reject-forms:hover {
  background-color: #fb4b7c;
  color: #fff;
}

.create-new__btn {
  padding: 13px 25px;
  color: #fff;
}

.create-new__btn_img {
  width: 19px;
  height: 19px;
  display: block;
  background: url("../img/btn/plus.png");
  margin-left: 20px;
}

.ssu-pagination {
  font-size: 12px;
  margin-top: 40px;
}

.ssu-pagination__btn {
  padding: 11px 20px;
  font-size: 12px;
  margin: 0 50px;
  color: #fff;
}

.ssu-pagination__btn_disabled {
  background-color: rgba(165, 165, 165, 0.5);
  cursor: default;
}

.ssu-pagination__btn_img-left {
  width: 34px;
  height: 17px;
  display: block;
  background: url("../img/btn/arrow.png") no-repeat;
  transform: scaleX(-1);
  margin-right: 10px;
}

.ssu-pagination__btn_img-right {
  width: 34px;
  height: 17px;
  display: block;
  background: url("../img/btn/arrow.png") no-repeat;
  margin-left: 10px;
}

.ssu-pagination__btn_number-page {
  padding: 11px 15px;
  font-size: 12px;
  margin: 0 5px;
  border: 2px solid #004d90;
  background-color: transparent;
  color: #004d90;
  transition: all 0.3s ease;
}

.ssu-pagination__btn_number-page_active {
  padding: 11px 15px;
  font-size: 12px;
  margin: 0 5px;
  border: 2px solid #004d90;
  background-color: #004d90;
  color: #fff;
}

.ssu-pagination__btn_number-page:hover {
  background-color: #004d90;
  color: #fff;
}

footer {
  font-size: 12px;
  background-color: #f0f0f0;
  color: #5c5c5c;
  height: 80px;
  bottom: 0;
  left: 0;
  width: 100%;
}

footer a {
  color: #1b71ba;
}

/* tlna = table-list-new-application */
.ssu-tlna {
  height: 145px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;
}

.ssu-tlna__header {
  height: 65px;
  border: 1px solid #e4e4e4;
}

.ssu-tlna__header__td {
  padding-left: 25px;
}

.ssu-tlna__header__name-form {
  color: #1b71ba;
  font-size: 18px;
  max-width: 950px;
}

.ssu-tlna__header__btn-group {
  padding-right: 15px;
}

.ssu-tlna__header__btn_view {
  padding: 8px 15px;
  font-size: 12px;
  border: none;
  color: #fff;
}

.ssu-tlna__header__btn_remind-via-email {
  padding: 8px 15px;
  font-size: 12px;
  border: none;
  color: #fff;
  margin-right: 8px;
  background-color: #45a9ff;
}

.ssu-tlna__body {
  cursor: default;
}

.ssu-tlna__body__td {
  padding: 10px 10px 10px 0px;
  min-width: 185px;
}

.ssu-tlna__body_caption {
  color: #9d9d9d;
}

.ssu-tlna__body_text {
  color: #333;
}

.ssu-tlna__body_a {
  text-size: 12px;
  color: #333;
}

.ssu-tlna__body__td:first-child {
  width: 400px;
  padding-left: 25px;
}

.ssu-tlna__body__td:nth-child(2) {
  width: 350px;
}

.ssu-tlna__body__td:last-child {
  padding-right: 25px;
}

input:-webkit-autofill {
  -webkit-box-shadow: inset 0 0 0 50px #fff !important;
  /* Цвет фона */
  -webkit-text-fill-color: #787878 !important;
  /* цвет текста */
  color: #787878 !important;
  /* цвет текста */
}

.ssu-login {
  margin: 68px auto 0px;
  padding: 20px 20px;
  background-color: #fff;
  min-height: 275px;
  width: 430px;
  border-bottom: 1px solid #9ec7e5;
}

.ssu-login__form {
  position: relative;
}

.ssu-login__label {
  padding: 13px 0px 33px;
  font-size: 18px;
}

.ssu-login__input-email, .ssu-login__input-password {
  height: 15px;
  width: 398px;
  padding: 15px;
  border: 1px solid #cecece;
  color: #787878;
}

.ssu-login__input-password {
  border-top: none;
  margin-bottom: 22px;
}

.ssu-login__input-img-email, .ssu-login__input-img-password {
  position: absolute;
  height: 46px;
  width: 46px;
  background: url("../img/sprites/sprite_login.png");
  top: 68px;
  right: 0;
}

.ssu-login__input-img-password {
  background-position: 0 -46px;
  top: 112px;
}

.ssu-login__remember {
  padding-left: 10px;
}

.ssu-login__remember_label {
  font-size: 13px;
  font-weight: normal;
  padding-left: 10px;
  color: #787878;
}

.ssu-login__remember_checkbox {
  padding-left: 10px;
}

.ssu-login__help-block {
  margin-bottom: 10px;
}

.ssu-login__btn {
  padding-top: 33px;
}

.ssu-login__btn_enter {
  padding: 11px 25px;
  font-size: 15px;
  border: none;
  color: #fff;
}

.ssu-login__btn_enter_img {
  width: 34px;
  height: 17px;
  display: block;
  background: url("../img/btn/arrow.png") no-repeat;
  margin-left: 25px;
}

.checkbox-sm_label {
  cursor: pointer;
  padding-left: 10px;
  vertical-align: text-top;
  color: #333;
}

.checkbox-sm_img {
  height: 15px;
  width: 15px;
  display: block;
  position: relative;
}

.checkbox-sm_input + span {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: url("../img/sprites/sprite_checkbox_top_bg_no_small.png") 0 0px no-repeat;
  transition: background-position 0.3s ease;
  border-top: 1px solid #c9c9c9;
  cursor: pointer;
}

.checkbox-sm_input:checked + span {
  background-position: 0 -15px;
}

.ssu-constructor-form__name-new-form {
  padding-top: 35px;
}

.ssu-constructor-form__name-new-form_label {
  margin-left: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.ssu-constructor-form__name-new-form_input {
  height: 60px;
  background-color: #fff;
  border: 1px solid #eee;
  padding: 0 25px;
  font-size: 18px;
  box-sizing: border-box;
}

.ssu-constructor-form__btn_save-new-form {
  position: relative;
  top: 21px;
  color: #fff;
  font-size: 15px;
  background-color: #21a961;
  padding: 10px 20px 10px 30px;
  border: none;
}

.ssu-constructor-form__btn_accept_forms_img {
  height: 19px;
  width: 19px;
  background: url("../img/btn/accept.png") no-repeat;
  margin-left: 40px;
}

.ssu-filters__td {
  width: 220px;
  padding-right: 20px;
}

.ssu-filters__td_label {
  position: relative;
  padding: 25px 0 10px 5px;
  font-size: 15px;
  color: #333;
}

.ssu-filters__td_input {
  height: 40px;
  width: 220px;
  padding: 0 15px;
  background-color: #fff;
  border: 1px solid #eee;
  font-size: 15px;
  box-sizing: border-box;
  cursor: pointer;
}

.ssu-filters__td_input_list {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 10px 10px 0;
  overflow: hidden;
  white-space: nowrap;
}

.ssu-filters__td_input_list_img-plus {
  content: "";
  height: 38px;
  width: 40px;
  background: url("../img/btn/add_in_list_gradient.png") -60px 0px no-repeat;
  display: block;
  position: absolute;
  top: 53px;
  right: 0;
  cursor: pointer;
  z-index: 10;
}

.ssu-filters__td_input_list_img-gradient {
  content: "";
  height: 38px;
  width: 60px;
  background: url("../img/btn/add_in_list_gradient.png") 0px 0px no-repeat;
  display: block;
  position: absolute;
  top: 52px;
  right: 38px;
  cursor: pointer;
  z-index: 10;
}

.ssu-filters__td_input_list_img-gradient-right {
  content: "";
  height: 38px;
  width: 60px;
  background: url("../img/btn/add_in_list_gradient.png") 0px 0px no-repeat;
  display: block;
  position: absolute;
  top: 52px;
  right: 1px;
  cursor: pointer;
  z-index: 10;
}

.ssu-filters__td_input:disabled {
  background: #d4d4d4;
  cursor: default;
}

.ssu-filters__td_w-390 {
  width: 390px;
  max-width: 390px;
}

.ssu-list-fields-will-form {
  width: 49%;
  margin-top: 30px;
  margin-right: 2%;
  cursor: default;
}

.ssu-list-fields-will-form__empty {
  height: 60px;
  background: #fff;
  font-size: 18px;
  color: #333;
  border: 1px solid #eaeaea;
}

.ssu-list-fields-will-form__name-table {
  height: 90px;
  font-size: 18px;
  color: #333;
}

.ssu-list-fields-will-form__label {
  height: 32px;
  color: #636363;
  vertical-align: top;
}

.ssu-list-fields-will-form__label__name-field {
  color: #333;
}

.ssu-list-fields-will-form__label__required {
  width: 95px;
}

.ssu-list-fields-will-form__field {
  background: #fff;
  height: 60px;
  border: 1px solid #eaeaea;
}

.ssu-list-fields-will-form__field__td__move {
  height: 59px;
  width: 47px;
  background: url("../img/move.png");
}

.ssu-list-fields-will-form__field__td__type-field {
  min-width: 160px;
  color: #636363;
}

.ssu-list-fields-will-form__field__td__required {
  width: 95px;
}

.ssu-list-fields-will-form__field__td__remove {
  width: 60px;
  cursor: pointer;
}

.ssu-list-fields-will-form__field__remove {
  height: 29px;
  width: 28px;
  background: url("../img/sprites/sprite_circle_btn.png") no-repeat;
  margin-right: 15px;
}

.ssu-list-existing-fields {
  width: 49%;
  margin-top: 30px;
  cursor: default;
}

.ssu-list-existing-fields__name-table {
  height: 90px;
  font-size: 18px;
  color: #333;
}

.ssu-list-existing-fields__label {
  height: 32px;
  color: #636363;
  vertical-align: top;
}

.ssu-list-existing-fields__label__name-field {
  color: #333;
}

.ssu-list-existing-fields__label__actions-with-fields {
  padding-right: 20px;
}

.ssu-list-existing-fields__field {
  background: #fff;
  height: 60px;
  border: 1px solid #eaeaea;
}

.ssu-list-existing-fields__field__td__add {
  width: 60px;
  cursor: pointer;
  transition: margin-left 0.3s ease;
}

.ssu-list-existing-fields__field__td__add:hover .ssu-list-existing-fields__field__add {
  margin-left: 10px;
}

.ssu-list-existing-fields__field__td__type-field {
  color: #636363;
}

.ssu-list-existing-fields__field__td__review {
  padding-right: 15px;
  color: #636363;
}

.ssu-list-existing-fields__field__add {
  height: 29px;
  width: 28px;
  background: url("../img/sprites/sprite_circle_btn.png") 0 -29px no-repeat;
  margin-left: 15px;
  transition: margin-left 0.3s ease;
}

.ui-state-highlight {
  height: 60px;
  background-color: #21a961;
}

.ui-handle:hover {
  cursor: n-resize;
}

.ssu-btn__managers_form, .ssu-btn__providers_of_information, .ssu-btn__departments_connect_to_form {
  position: relative;
  background: #dfdfdf;
  padding: 2px 15px;
  margin-left: 10px;
}

.ssu-check-forms__info {
  background: #fff;
  margin-top: 25px;
  border: 1px solid #e4e4e4;
  cursor: default;
}

.ssu-check-forms__info__header {
  height: 60px;
  border-bottom: 1px solid #e4e4e4;
}

.ssu-check-forms__info__header_name-form {
  padding: 0 25px;
  font-size: 24px;
  color: #333;
}

.ssu-check-forms__info__header_btn {
  padding: 0 15px;
}

.ssu-check-forms__info__header_btn_remind-via-email {
  padding: 8px 15px;
  background-color: #45a9ff;
  color: #fff;
  border: none;
}

.ssu-check-forms__info__body {
  height: 80px;
  font-size: 15px;
}

.ssu-check-forms__info__body__cell {
  padding: 0 25px;
}

.ssu-check-forms__info__body__cell_caption {
  color: #9d9d9d;
}

.ssu-check-forms__info__body__cell_text {
  color: #333;
}

.ssu-check-forms__content {
  width: 100%;
  border-bottom: 1px solid #e4e4e4;
}

.ssu-check-forms__content__header_text {
  height: 18px;
  padding: 25px 5px 15px;
  font-size: 18px;
  color: #333;
  cursor: default;
}

.ssu-check-forms__content__body_caption {
  color: #9d9d9d;
  font-size: 15px;
  margin: 25px 0px 10px 4px;
}

.ssu-check-forms__content__body_text {
  color: #333;
  font-size: 15px;
}

.ssu-check-forms__content__body_values {
  color: #333;
  font-size: 16px;
}

.ssu-check-forms__content__body_textarea {
  resize: none;
  width: 96%;
  border: 1px solid #e0e0e0;
  color: #333;
  font-size: 15px;
  padding: 10px;
}

.ssu-check-forms__content__body_sub-element {
  padding: 5px 0;
}

.ssu-check-forms__content__body_current pre,
.ssu-check-forms__content__body_previous pre {
  font-family: Arial;
}

.ssu-check-forms__content__body_current {
  width: 50%;
  background: #fff;
  border: 1px solid #e4e4e4;
  padding: 0 25px 25px;
}

.ssu-check-forms__content__body_previous {
  width: 50%;
  background: #f0f0f0;
  border: 1px solid #e4e4e4;
  cursor: default;
  position: relative;
  padding: 0 25px 25px;
}

.ssu-check-forms__content__body_previous_img_next {
  height: 42px;
  width: 42px;
  background: url("../img/btn/versions_form.png") no-repeat;
  position: absolute;
  top: 25px;
  left: -21px;
  cursor: pointer;
}

.ssu-check-forms__content__body_previous_img_previous {
  height: 42px;
  width: 42px;
  background: url("../img/btn/versions_form.png") no-repeat;
  transform: scale(-1, 1);
  position: absolute;
  top: 25px;
  right: -21px;
  cursor: pointer;
}

.ssu-check-forms__btn {
  margin-top: 25px;
  height: 40px;
  width: 50%;
  color: #fff;
  font-size: 15px;
}

.ssu-check-forms__btn_reject-form, .ssu-check-forms__btn_accept-form {
  padding: 10px 22px 10px 35px;
  color: #fff;
  font-size: 15px;
  border: none;
  background: #21a961;
}

.ssu-check-forms__btn_reject-form_img, .ssu-check-forms__btn_accept-form_img {
  height: 19px;
  width: 19px;
  background: url("../img/sprites/sprite_check_forms.png") 0 0 no-repeat;
  margin-left: 30px;
}

.ssu-check-forms__btn_reject-form {
  background: #da4c7a;
}

.ssu-check-forms__btn_reject-form_img {
  background: url("../img/sprites/sprite_check_forms.png") 0 -19px no-repeat;
}

.ssu-check-forms__message {
  width: 50%;
  padding-top: 30px;
}

.ssu-check-forms__message_caption {
  padding-left: 5px;
  font-size: 18px;
  cursor: default;
  color: #333;
}

.ssu-check-forms__message_textarea {
  margin-top: 10px;
  padding: 20px;
  height: 75px;
  width: 94%;
  resize: none;
  font-size: 15px;
  font-family: Arial;
  background-color: #fff;
  border: 1px solid #e4e4e4;
}

.ssu-check-forms__message-box {
  width: 100%;
  margin-top: 30px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
}

.message_info {
  padding: 10px;
  height: 18px;
}

.message_info_time-send, .message_info_name-sender {
  font-size: 11px;
}

.message_info_new-message {
  position: relative;
  padding: 0px 3px 1px;
  width: 22px;
  font-size: 11px;
  font-weight: 600;
  color: #fff;
  background-color: orange;
}

.message_content {
  padding: 0px 10px;
  font-size: 15px;
  text-align: justify;
}

.ssu-table-elements__plus__td-btn {
  display: inline-block;
  padding: 9px 14px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ssu-table-elements__minus__td-btn {
  display: inline-block;
  padding: 9px 14px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ssu-table-elements tr {
  border-top: 5px solid transparent;
}

.ssu-view-old-version-form__btn {
  margin-top: 25px;
  height: 40px;
  color: #fff;
  font-size: 15px;
}

.ssu-view-old-version-form__btn_get-back {
  padding: 10px 20px 10px 15px;
  color: #fff;
  font-size: 15px;
  border: none;
  background: #1b71ba;
  text-decoration: none;
}

.ssu-view-old-version-form__btn_get-back_img {
  height: 17px;
  width: 35px;
  background: url("../img/btn/arrow.png") no-repeat;
  transform: scaleX(-1);
  margin-right: 20px;
}
