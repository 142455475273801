.ssu-list-table {
  border-collapse: collapse;
  font-size: 15px;

  &__header {
    background: #e0e0e0;
    height: 60px;

    &__td:first-child {
      padding-left: 30px;
      width: 450px;
    }
    &__td{
      cursor: default;
    }
    &__td:last-child {
      padding-right: 30px;
      cursor: default;
    }
    &__text{
      color: #333;
      cursor: pointer;
    }
    &_arrows-sort {
      &_img {
        width: 19px;
        height: 19px;
        background: url('../img/btn/arrows_sort.png');
        vertical-align: sub;
        cursor: pointer;
      }
    }
  }

  &__a-name {
    color: #1b71ba;
    transition: color 0.3s ease;
  }
  &__a-name:hover {
    color: #333;
  }

  &__body {
    background-color: #fff;
    height: 60px;
    cursor: default;

    &__td:first-child {
      padding-left: 30px;
    }
    &__td {
      border-bottom: 2px solid #f0f0f0;
      color: #636363;
      padding: 0 10px;
    }

  }
  &__body:hover {
    background-color: #fafafa;
  }

  &__btn {
    &_actions,
    &_actions-red,
    &_actions-green {
      padding: 8px 16px;
      font-size: 12px;
      color: #494949;
      transition: all 0.2s ease;
      margin: 0 5px;
    }
    &_actions:hover {
      background-color: #1b71ba;
      color: #fff;
    }
    &_actions-red:hover {
      background-color: #fb4b7c;
      color: #fff;
    }
    &_actions-green:hover {
      background-color: #21a961;
      color: #fff;
    }
  }

}