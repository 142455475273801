.ssu-navbar-top{
  height: 73px;
  color: #fff;
  background-color: #1b71ba;
  font-size: 15px;
  overflow: hidden;

  &_wrapper{
    padding-right: 20px;
    padding-left: 20px;
    margin-right: auto;
    margin-left: auto;
  }
  &__li{
    display: inline;
    color: #fff;

    &_active{
      text-decoration-color: #fff;
      background-color: #2e8ad9;
    }
  }

  &__a{
    padding: 28px;
    text-decoration: none;
    transition: all 0.1s;
  }

  &__a:hover{
    text-decoration-color: #fff;
    background-color: #2e8ad9;
  }

  &__btn{
    &_how-it-works{
      padding: 10px 30px;
    }
  }
}