.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
  z-index: 99999;
  display: none;
  pointer-events: none;
  cursor: default;

  &__heading {
    margin: 40px auto 0;
    position: relative;
    width: 652px;
    height: 60px;
    background-color: #1b71ba;
    color: #fff;
    font-size: 18px;

    &__text {
      padding: 22px 20px;
    }
  }
  &__body {
    width: 650px;
    max-height: 500px;
    position: relative;
    margin: 0px auto ;
    border: 1px solid #e9e9e9;
    background: #fff;
    overflow: auto;
  }
  &__close {
    position: absolute;
    height: 20px;
    width: 20px;
    bottom: 20px;
    right: 20px;
    background: url('../img/btn/reset.png');
    transition: transform 0.3s ease;
  }
  &__close:hover {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  &__table {
    width: 100%;
    &_td {
      padding: 10px;
    }
  }
}
.modal:target {
  display: block;
  pointer-events: auto;
}
