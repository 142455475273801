/* tlna = table-list-new-application */

.ssu-tlna {
  height: 145px;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  margin-bottom: 20px;

  &__header {
    height: 65px;
    border: 1px solid #e4e4e4;

    &__td {
      padding-left: 25px;
    }
    &__name-form {
      color: #1b71ba;
      font-size: 18px;
      max-width: 950px;
    }
    &__btn-group {
      padding-right: 15px;
    }
    &__btn {

      &_view {
        padding: 8px 15px;
        font-size: 12px;
        border: none;
        color: #fff;
      }
      &_remind-via-email {
        padding: 8px 15px;
        font-size: 12px;
        border: none;
        color: #fff;
        margin-right: 8px;
        background-color: #45a9ff;
      }
    }
  }

  &__body {
    cursor: default;

    &__td {
      padding: 10px 10px 10px 0px;
      min-width: 185px;
    }

    &_caption {
      color: #9d9d9d;
    }
    &_text {
      color: #333;
      //text-decoration: underline;
    }
    &_a {
      text-size: 12px;
      color: #333;
    }

    &__td:first-child {
      width: 400px;
      padding-left: 25px;
    }
    &__td:nth-child(2) {
      width: 350px;
    }
    &__td:last-child {
      padding-right: 25px;
    }
  }

}