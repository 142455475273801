.ssu-header {
  height: 73px;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;

  &__name-system {
    padding-left: 25px;
    font-size: 18px;
    color: #5a5a5a;
  }
  &__user-name {
    height: 72px;
    //width: 224px;
    padding: 1px 25px;
    vertical-align: middle;
    color: #1b71ba;
    &_text {
      text-decoration: underline;
    }
    &_caret {
      margin-left: 5px;
    }
  }
  &__user-name:hover {
    //padding: 0 24px;
    //border: 1px solid #e0e0e0;
    border-bottom: none;
    color: #494949;
  }
  &__user-name:hover &__user-name_text{
    text-decoration: none;
  }
  &__user-name:hover .drop-down {
    top: 73px;
    right: 0px;
    opacity: 1;
    width: 271px;
    border-top: none;
    border: 1px solid #e0e0e0;
  }

  &__btn {
    &_how-it-works {
      padding: 11px 20px;
      margin-right: 20px;
    }
  }

}

.drop-down {
  position: absolute;
  top: -215px;
  list-style: none;
  background: #fff;
  border: 1px solid #e0e0e0;
  opacity: 1;
  z-index: 1000;

  &__li {
    padding: 9px 25px;

    &_edit-profile {
      &_img {
        height: 19px;
        width: 19px;
        margin-right: 20px;
        background: url("../img/sprites/sprite_user_menu.png") 0 0px;
      }
    }
    &_log-manager {
      &_img {
        height: 19px;
        width: 19px;
        margin-right: 20px;
        background: url("../img/sprites/sprite_user_menu.png") 0 38px;
      }
    }
    &_exit {
      &_img {
        height: 19px;
        width: 19px;
        margin-right: 20px;
        background: url("../img/sprites/sprite_user_menu.png") 0 19px;
      }
    }

  }

  &__a {
    color: #494949;
    text-decoration: underline;
  }
  &__a:hover {
    text-decoration: none;
  }
  &__a:first-child &__li{
    padding-top: 25px;
    padding-bottom: 25px;
  }
  &__a:nth-child(2) &__li {
    padding-bottom: 25px;
  }
  &__a:last-child &__li {
    padding: 25px;
    border-top: 1px solid #e0e0e0;
  }

}