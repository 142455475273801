.ssu-view-old-version-form{
  &__btn {
    margin-top: 25px;
    height: 40px;
    color: #fff;
    font-size: 15px;

    &_get-back {
      padding: 10px 20px 10px 15px;
      color: #fff;
      font-size: 15px;
      border: none;
      background: #1b71ba;
      text-decoration: none;

      &_img {
        height: 17px;
        width: 35px;
        background: url('../img/btn/arrow.png') no-repeat;
        transform: scaleX(-1);
        margin-right: 20px;
      }
    }
  }
}