.ssu-forms-status{
  padding: 6px 0;

  &__btn {
    &_all-forms,
    &_new-forms,
    &_accept-forms,
    &_reject-forms {
      border: none;
      padding: 0px 20px 0px 5px;//5px 20px 6px 5px;
      margin-right: 5px;
      transition: all 0.1s;

      &_img {
        padding-right: 9px;
        font-size: 15px;
        height: 29px;
        width: 29px;
      }
      &_text {
        text-decoration: underline;
        line-height: 29px;
      }
    }
    &_all-forms {
      &_img {
        background: url('../img/sprites/sprite_circle_btn.png') 0 -87px no-repeat;
      }
    }
    &_new-forms {
      &_img {
        background: url('../img/sprites/sprite_circle_btn.png') 0 -116px no-repeat;
      }
    }
    &_accept-forms {
      &_img {
        background: url('../img/sprites/sprite_circle_btn.png') 0 -58px no-repeat;
      }
    }
    &_reject-forms {
      &_img {
        background: url('../img/sprites/sprite_circle_btn.png') 0 0px no-repeat;
      }
    }
    &_all-forms_active,
    &_all-forms:hover,
    &_new-forms_active,
    &_new-forms:hover {
      background-color: #1b71ba;
      color: #fff;
    }
    &_accept-forms_active,
    &_accept-forms:hover {
      background-color: #21a961;
      color: #fff;
    }
    &_reject-forms_active,
    &_reject-forms:hover {
      background-color: #fb4b7c;
      color: #fff;
    }

  }
}