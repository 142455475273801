.ssu-card{
  width: 100%;
  margin: 20px auto;
  background-color: #fff;
  border: 1px solid #dedede;

  &__header{
    height: 60px;
    background-color: #1b71ba;

    &_user-name{
      color: #fff;
      padding: 0 20px;
    }
  }

  &__body{
    padding: 20px;

    &_caption{
      color: #9d9d9d;
      font-size: 15px;
      padding: 20px 5px 5px;
    }
    &_caption:first-child{
      padding-top: 0px;
    }
    &_input{
      border: 1px solid #dedede;
    }

    &_textarea{
      border: 1px solid #dedede;
      resize: none;
      font-size: 15px;
      padding: 15px;
      width: 94%;
    }
    &__btn{
      padding-top: 20px;

      &_save{
        padding: 10px 30px;
        border: 2px solid #21a961;
        background-color: #21a961;
        color: #fff;
      }
      &_generate-password{
        padding-top: 3px;
        border: none;
        color: blue;
      }
    }
  }
}