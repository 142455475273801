.create-new{

  &__btn{
    padding: 13px 25px;
    color: #fff;

    &_img{
      width: 19px;
      height: 19px;
      display: block;
      background: url('../img/btn/plus.png');
      margin-left: 20px ;
    }
  }

}