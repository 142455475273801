.ssu-search{

  &_input{
    width: 350px;
    height: 43px;
    border: 1px solid #e0e0e0;
    background: #fff;
    outline: none;
    padding: 0 20px;
  }
  &_filter{
    height: 19px;
    width: 19px;
    background: url('../img/btn/filter.png') no-repeat;
    margin-left: 30px;
    padding-left: 25px;
  }
  &_advanced-search{
    text-decoration: underline;
    cursor: pointer;
    font-size: 15px;
  }
}

.ssu-btn {

  &_search {
    height: 45px;
    width: 45px;
    border: none;
    border-radius: 0px;
    background-color: #1b71ba;

    &_img {
      width: 19px;
      height: 19px;
      display: inline-block;
      background: url('../img/btn/magnifier.png') no-repeat;
    }
  }

  &_reset {
    height: 45px;
    width: 45px;
    border: 2px solid #bebebe;
    background-color: none;
    margin-left: 10px;

    &_img {
      width: 19px;
      height: 19px;
      display: inline-block;
      background: url('../img/btn/reset.png') no-repeat;
    }
  }

}